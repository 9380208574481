import React from 'react';
import PropTypes from 'prop-types';

import DefaultFrontPage from './DefaultFrontPage.js';
import UvildigraadFrontPage from './UvildigraadFrontPage.js';
import F10FrontPage from './F10FrontPage.js';
import PageTemplate from '../PageTemplate/PageTemplate.js';
import { renderDate } from '../../../../utils/renderingService.js';

const pages = {
    uvildigraad: UvildigraadFrontPage,
    f10: F10FrontPage,
};

export default function FrontPage(props) {
    const { state } = props;
    const { customer, investData } = state;
    const { date } = investData.result;

    const parsedDate = date.seconds ? new Date(date.seconds * 1000) : new Date(date);

    const Page = pages[props.state.partnerData.partnerId] || DefaultFrontPage;
    return (
        <PageTemplate
            { ...props }
            alternativeFooter
        >
            <div style={{ textAlign: 'center' }}>
                <Page />
                <div style={{ width: 600, marginTop: 120, marginLeft: 'auto', marginRight: 'auto' }}>
                    <p style={{ textTransform: 'upperCase', fontSize: 50, fontWeight: 'bold', display: 'block' }}>
                        InvestTjek
                    </p>
                    <hr />
                    <p style={{ fontSize: 30, display: 'block' }}>{ customer.name }</p>
                    <p style={{ fontSize: 10, display: 'block' }}>Beregnet { renderDate(parsedDate) }</p>
                </div>
            </div>
        </PageTemplate>
    );
}

FrontPage.propTypes = {
    state: PropTypes.object.isRequired,
};
