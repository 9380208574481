import React from "react";
import PropTypes from "prop-types";

import DefaultPageTemplate from "./DefaultPageTemplate.js";
import UvildigraadPageTemplate from "./UvildigraadPageTemplate.js";
import F10PageTemplate from "./F10PageTemplate.js";

const templates = {
  uvildigraad: UvildigraadPageTemplate,
  f10: F10PageTemplate,
};

function PageTemplate(props) {
  const { state = false } = props;
  if (!state || !state.partnerData)
    return <DefaultPageTemplate>{props.children}</DefaultPageTemplate>;

  const Template =
    templates[state.partnerData.partnerId] || DefaultPageTemplate;
  return <Template {...props} />;
}

PageTemplate.propTypes = {
  state: PropTypes.any,
  children: PropTypes.node.isRequired,
};

export default PageTemplate;
