import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalStyles, createTheme, ThemeProvider } from '@mui/material';
import getOverrides from './UIComponents/muiOverrides.js';
import BrowserSupportModal from './BrowserSupportModal.js';
import Routes from '../routes.js';

const App = () => {
    const theme = useSelector((state) => state.site.theme);
    const muiTheme = createTheme({
        palette: {
            primary: {
                main: theme.colors.primary,
            },
            secondary: {
                main: theme.colors.secondary,
            },
            backgroundColor: {
                default: theme.colors.mainBackground,
            },
        },
        spacing: theme.spacing,
        components: getOverrides(theme),
    });

    return (
        <ThemeProvider theme={ muiTheme }>
            <GlobalStyles
                styles={{
                    '.Mui-selected .MuiTypography-root': {
                        fontWeight: 500,
                    },
                    '.MuiTableRow-root.MuiTableRow-hover:hover': {
                        backgroundColor: `${muiTheme.palette.primary.light} !important`,
                        cursor: 'pointer',
                    },
                    '.chrome-picker': {
                        boxShadow: 'none !important',
                    },
                    '.chrome-picker > *': {
                        borderRadius: '0px !important',
                    },
                }}
            />
            <BrowserSupportModal />
            <Routes />
        </ThemeProvider>
    );
};

export default App;
