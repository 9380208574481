import { v4 as uuidv4 } from 'uuid';
import { intro as introGroupType, welcomeMessage as welcomeMessageGroupType, comment as commentGroupType } from '../../reducers/onboardingQuestionGroupTypes.js';

export function GetMergedQuistionair(questionnaire) {
    const introGroup = questionnaire?.questionGroups
        .filter(localQuestionGroup => localQuestionGroup.type === introGroupType)
        .map(localQuestionGroup => {
            return {
                ...localQuestionGroup,
            };
        } );

    const customerQuestionGroups = questionnaire?.questionGroups
        .filter(localQuestionGroup => localQuestionGroup.type !== introGroupType && localQuestionGroup.questions.some(question => !question?.shared && !question?.alwaysShared))
        .map(localQuestionGroup => {
            return {
                ...localQuestionGroup,
                questions: localQuestionGroup.questions.filter(question => !question?.shared && !question?.alwaysShared),
                responder: 'customer',
            };
        } );

    const commentsToAdvisor = questionnaire?.questionGroups
        .filter(localQuestionGroup => localQuestionGroup.type === commentGroupType)
        .map(localQuestionGroup => {
            return {
                ...localQuestionGroup,
            };
        } );

    if(!questionnaire?.includeSpouse) {
        return [
            ...introGroup,
            ...customerQuestionGroups,
            ...commentsToAdvisor,
        ];
    }

    const spouseQuestionGroups = questionnaire?.questionGroups
        .filter(localQuestionGroup => localQuestionGroup.type !== introGroupType && localQuestionGroup.questions.some(question => !question?.shared && !question?.alwaysShared))
        .map(localQuestionGroup => {
            return {
                ...localQuestionGroup,
                questions: localQuestionGroup.questions.filter(question => !question?.shared && !question?.alwaysShared),
                responder: 'spouse',
            };
        } );

    const sharedQuestionsGroups = questionnaire?.includeSpouse ? questionnaire?.questionGroups
        .filter(localQuestionGroup => localQuestionGroup.type !== introGroupType && localQuestionGroup.questions.some(question => question?.shared || question?.alwaysShared))
        .map(localQuestionGroup => {
            return {
                ...localQuestionGroup,
                questions: localQuestionGroup.questions.filter(question => question?.shared || question?.alwaysShared),
                responder: 'shared',
            };
        } ) : [];

    return [
        ...introGroup,
        { type: welcomeMessageGroupType, responder: 'customer', id: uuidv4() },
        ...customerQuestionGroups,
        { type: welcomeMessageGroupType, responder: 'spouse', id: uuidv4() },
        ...spouseQuestionGroups,
        { type: welcomeMessageGroupType, responder: 'shared', id: uuidv4() },
        ...sharedQuestionsGroups,
    ];
}
