import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CircularProgress , Button } from '@mui/material';

import '@algostrata/risk-questionnaire';
import { getMifidProcess, updateMifidData } from '../../actions/mifidActions.js';
import LoadingPage from '../LoadingPage.js';
import { loginWithMagicLink, logout } from '../../actions/authActions.js';
import { getMagicLinkToken } from '../../databaseRepository/magicLinkDataManager.js';
import { isNullOrWhitespace } from '../../utils/helperFunctions.js';

export default function MifidLink() {
    const dispatch = useDispatch();
    const location = useLocation();
    const mifidProcessesData = useSelector(state => state.mifidProcessesData);
    const partnerName = useSelector(state => state.partnerData?.name ?? '');
    const [partnerId, setPartnerId] = useState(null);
    const loggedIn = useSelector(state => state.auth.loggedIn);
    const globalLoading = useSelector(state => state.auth.globalLoading);
    const loginFailed = useSelector(state => state?.auth?.loginFailed ?? false);
    const [processId, setProcessId] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [closing, setClosing] = useState(false);
    const [closed, setClosed] = useState(false);
    const [finished, setFinished ] = useState(false);
    const [partialClose, setPartialClose ] = useState(false);
    const urlParts = location.pathname;
    const token = useMemo(() => {
        const urlPartsSplit = urlParts.split('/');
        return urlPartsSplit[urlPartsSplit.length - 1];
    }, [urlParts]);
    const process = mifidProcessesData?.processes?.find(x => x.processId === processId);
    const mifidData = process?.mifidData;
    useEffect(() => {
        async function getData(){
            if(!isNullOrWhitespace(token) && !globalLoading && !closed) {
                if (!loggedIn) await dispatch(loginWithMagicLink(token));
                const tokenData = await getMagicLinkToken(token);
                setProcessId(tokenData.processId);
                await dispatch(getMifidProcess(tokenData.partnerId, tokenData.customerId, tokenData.processId));
                setPartnerId(tokenData.partnerId);
                setLoaded(true);
            }
        }
        getData();
    }, [dispatch, globalLoading, loggedIn, token, closed]);

    const onClose = useCallback(async event => {
        setClosing(true);
        const usedQuestionnaire = mifidData?.questionnaire;
        await dispatch(updateMifidData(process?.partnerId, process?.customerId, processId,
            {
                selectedQuestions: event.detail.selectedQuestions,
                comment: event.detail.comment ?? null,
                finished: event.detail.finished,
                questionnaire: usedQuestionnaire,
            }));

        setClosed(true);
        setFinished(event.detail.finished);
        if(event.detail.finished) await dispatch(logout());
    }, [dispatch, mifidData, process, processId]);

    async function onPartialClose(closeAnyway) {
        if(closeAnyway) {
            setPartialClose(true);
            await dispatch(logout());
        } else {
            setClosing(false);
            setClosed(false);
            setPartialClose(false);
        }
    }

    useEffect (() => {
        const form = document.getElementById ('myRiskQuestionnaire');
        if(form) {
            form.addEventListener('on-close', onClose);
            return () => {
                form.removeEventListener('on-close', onClose);
            };
        }
    });

    if(loginFailed) {
        // Simulate an HTTP redirect:
        alert('Linket er ugyldigt!');
        window.location.replace('http://monax.dk');
    }

    let imageObj = null;
    switch (partnerId) {
        case 'uvildigraad':
            imageObj = <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '450px', height: 'auto', padding: '25px 0' }} src="/assets/img/uvildigraad/logo.png" />;
            break;
        case 'f10':
            imageObj = <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '300px', height: 'auto', paddingTop: '25px 0' }} src="/assets/img/f10/logo-2.png" />;
            break;
        case 'ml-advice':
            imageObj = (
                <>
                    <p style={{ fontSize: 40, display: 'block', textAlign: 'center' }}>monax</p>
                    <p style={{ fontSize: 20, display: 'block', textAlign: 'center' }}>- for rigtige rådgivere -</p>
                </>
            );
            break;
        default:
            break;
    }

    if(closing && !partialClose) {
        return (
            <div style={{ textAlign: 'center', margin: '50px' }} >
                { imageObj }
                <h1>MiFID Risikospørgeskema { partnerName }</h1>
                <div style={{ display: 'inline-block', width: '50%', textAlign: 'center', border: '2px solid #ccc', borderRadius: '16px', padding: '12.5px 25px' }} >
                    { !closed ? (
                        <>
                            Vi er ved at sende din besvarelse vent venligst med at lukke siden!
                            <br />
                            <CircularProgress color="secondary" />
                        </>
                    ) : (
                        <>
                            { finished ? 'Din besvarelse er nu sendt og du kan nu lukke siden.' : (
                                <>
                                    Din delbesvarelse er nu sendt. Er du sikker på du vil afslutte uden at have svaret på alle spørgsmål?
                                    <br />
                                    Du kan vende tilbage senere og udfylde resten af sprøgeskemaet.
                                    <br />
                                    <Button onClick={ () => onPartialClose(true) }>Ja</Button> <Button onClick={ () => onPartialClose(false) }>Nej</Button>
                                </>
                            ) }
                        </>
                    )
                    }
                </div>
            </div>
        );
    }

    if(partialClose) {
        return(
            <div style={{ textAlign: 'center', margin: '50px' }} >
                { imageObj }
                <h1>MiFID Risikospørgeskema { partnerName }</h1>
                <div style={{ display: 'inline-block', width: '50%', textAlign: 'center', border: '2px solid #ccc', borderRadius: '16px', padding: '12.5px 25px' }} >
                    Du kan nu lukke siden. Du kan vende tilbage senere og udfylde resten af sprøgeskemaet.
                </div>
            </div>
        );
    }

    if(!loaded || process?.isUpdating || !loggedIn || closing) return <LoadingPage />;

    return (
        <>
            { imageObj }
            <h1 style={{ textAlign: 'center' }}>MiFID Risikospørgeskema { partnerName }</h1>
            <risk-questionnaire
                id='myRiskQuestionnaire'
                initial-selected-questions={ JSON.stringify(mifidData?.selectedQuestions) }
                initial-comment={ mifidData?.comment }
                is-finished={ mifidData?.finished }
                disabled={ mifidData?.finished }
                card-layout={ true }
                questionnaire={ JSON.stringify(mifidData?.questionnaire) }
            ></risk-questionnaire>
        </>
    );
}
