import React from 'react';
import PropTypes from 'prop-types';
import { styled , TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';


const VisuallyHidden = styled('span')(()=> ({
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
}));

export default function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
        colConfig,
        inline,
    } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                { colConfig.map((colConfigMap, i) => (
                    <TableCell
                        key={ colConfigMap.id }
                        align={ i === 0 ? 'left' : 'right' }
                        padding={ colConfigMap.disablePadding ? 'none' : 'normal' }
                        sortDirection={ orderBy === colConfigMap.id ? order : false }
                    >
                        { props.sortable ? (
                            <TableSortLabel
                                active={ orderBy === colConfigMap.id }
                                direction={ orderBy === colConfigMap.id ? order : 'asc' }
                                onClick={ createSortHandler(colConfigMap.id) }
                            >
                                { colConfigMap.label }
                                { orderBy === colConfigMap.id ? (
                                    <VisuallyHidden>
                                        { order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending' }
                                    </VisuallyHidden>
                                ) : null }
                            </TableSortLabel>
                        ) : (
                            <span
                                style={
                                    inline
                                        ? { color: '#A0A0A0', fontSize: '15px', fontWeight: 500 }
                                        : {}
                                }
                            >
                                { colConfigMap.label }
                            </span>
                        ) }
                    </TableCell>
                )) }
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string,
    rowCount: PropTypes.number.isRequired,
    colConfig: PropTypes.array.isRequired,
    inline: PropTypes.bool,
    sortable: PropTypes.bool,
};
