import React from "react";
import PropTypes from "prop-types";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableFooter,
  CircularProgress,
  Paper,
} from "@mui/material";

import EnhancedTableHead from "./EnhancedTableHead.js";
import { getComparator, stableSort } from "./sort.js";

export default function Table(props) {
  const {
    rows,
    colConfig,
    onClick,
    label,
    labelElement,
    sortable = true,
    paginated = true,
    inline = false,
  } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(undefined);
  const [page, setPage] = React.useState(0);
  const rowsPerPageDefault = paginated ? 10 : rows.length;
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageDefault);

  React.useEffect(() => {
    setRowsPerPage(paginated ? 10 : rows.length);
  }, [paginated, rows.length]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper variant="outlined" style={{ margin: "10px 0" }}>
      <TableContainer style={props.style}>
        {(label || labelElement) && (
          <div
            style={{
              fontSize: "15px",
              marginLeft: "30px",
              marginBottom: "0px",
              marginTop: "30px",
              fontWeight: "bold",
            }}
          >
            {label || labelElement}
          </div>
        )}
        <MuiTable aria-label="table">
          <EnhancedTableHead
            sortable={sortable}
            inline={inline}
            colConfig={colConfig}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  hover={!inline && !row.loading}
                  tabIndex={-1}
                  key={index}
                  onClick={onClick && ((event) => onClick(event, row, index))}
                >
                  {row.loading ? (
                    <TableCell colSpan={colConfig.length} align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress size={18} color="secondary" />
                        {typeof row.loading === "string" ? row.loading : null}
                      </div>
                    </TableCell>
                  ) : (
                    colConfig.map((cell, i) => {
                      const Formatter = cell.formatter;
                      return (
                        <TableCell key={i} align={i === 0 ? "left" : "right"}>
                          {cell.formatter ? (
                            <Formatter row={row}>{row[cell.id]}</Formatter>
                          ) : (
                            row[cell.id]
                          )}
                        </TableCell>
                      );
                    })
                  )}
                </TableRow>
              ))}
          </TableBody>
          {paginated && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    rowsPerPageDefault,
                    20,
                    50,
                    100,
                    { label: "Alle", value: -1 },
                  ]}
                  colSpan={5}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton
                  showLastButton
                />
              </TableRow>
            </TableFooter>
          )}
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

Table.propTypes = {
  rows: PropTypes.array.isRequired,
  colConfig: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  inline: PropTypes.bool,
  sortable: PropTypes.bool,
  paginated: PropTypes.bool,
  label: PropTypes.string,
  labelElement: PropTypes.element,
  style: PropTypes.any,
};
